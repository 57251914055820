
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Flex,
	Heading,
	RadioGroup,
	Radio,
	Text
} from '@chakra-ui/react'
import { VscWarning } from 'react-icons/vsc';
import styled from '@emotion/styled';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }
const PositionApplyingFor = () => {
    const defaultValues = {
		applyingFor: '',
		shifts: '',
		contactEmployer: '',
		startDate: '',
		legallyEligible: '',
		travelAvailability: '',
	};

    const { register, formState: { errors } } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    //console.log(watchData);
    return (
        <>
		<SectionHeading>
								Position Applying For
							</SectionHeading>

							<Box textAlign='left' mb='8' w='100%'>
								<FormControl>
									<FormLabel>Job Title<RequiredSpan /></FormLabel>
									<Input
										type='text'
										{...register('applicantJobTitle')}
										autoComplete='on'
									/>
									<p style={errorStyles}>
										{errors.applicantJobTitle && (
											<HStack spacing='5px'>
												<VscWarning />{' '}
												<Box>{errors.applicantJobTitle?.message}</Box>
											</HStack>
										)}
									</p>
								</FormControl>
							</Box>

							<Box mb='8' w='100%'>
								<FormControl>
									<Flex w='100%' flexWrap='wrap'>
										<Box w={{ base: '100%', md: '33.3%' }}>
											<FormLabel>
												Are you applying for:<RequiredSpan />
											</FormLabel>
											<RadioGroup>
												<HStack spacing='18px'>
													<Radio
														value='F/T'
														name='applyingFor'
														{...register('applyingFor', {required: true})}
													>
														F/T
													</Radio>
													<Radio
														value='P/T'
														name='applyingFor'
														{...register('applyingFor', {required: true})}
													>
														P/T
													</Radio>

													<Radio
														value='Temp/Seasonal'
														name='applyingFor'
														{...register('applyingFor', {required: true})}
													>
														Temp/Seasonal
													</Radio>
												</HStack>
											</RadioGroup>
											<p style={errorStyles}>
										{errors.applyingFor && (
											<HStack spacing='5px'>
												<VscWarning />{' '}
												<Box>{errors.applyingFor?.message}</Box>
											</HStack>
										)}
									</p>
										</Box>
										<Box
											w={{ base: '100%', md: '33.3%' }}
											pl={{ base: 0, md: '4' }}
										>
											<FormLabel>
												What shifts will you work?<RequiredSpan />
											</FormLabel>
											<RadioGroup>
												<HStack spacing='18px'>
													<Radio
														value='Days'
														name='shifts'
														{...register('shifts', {required: true})}
													>
														Days
													</Radio>
													<Radio
														value='Nights'
														name='shifts'
														{...register('shifts', {required: true})}
													>
														Nights
													</Radio>
												</HStack>
											</RadioGroup>
											<p style={errorStyles}>
										{errors.shifts && (
											<HStack spacing='5px'>
												<VscWarning />{' '}
												<Box>{errors.shifts?.message}</Box>
											</HStack>
										)}
									</p>
										</Box>
										<Box
											w={{ base: '100%', md: '33.3%' }}
											pl={{ base: 0, md: '4' }}
										>
											<FormLabel>
												May We Contact Present Employer?<RequiredSpan />
											</FormLabel>
											<RadioGroup>
												<HStack spacing='18px'>
													<Radio
														value='Yes'
														name='contactEmployer'
														{...register('contactEmployer', {required: true})}
													>
														Yes
													</Radio>
													<Radio
														value='No'
														name='contactEmployer'
														{...register('contactEmployer', {required: true})}
													>
														No
													</Radio>
												</HStack>
											</RadioGroup>
											<p style={errorStyles}>
										{errors.contactEmployer && (
											<HStack spacing='5px'>
												<VscWarning />{' '}
												<Box>{errors.contactEmployer?.message}</Box>
											</HStack>
										)}
									</p>
										</Box>
									</Flex>
								</FormControl>
							</Box>

							<Box textAlign='left' mb='8' w='100%'>
								<FormControl>
									<FormLabel>Available Start Date:<RequiredSpan /></FormLabel>
									<Input
										type='date'
										{...register('startDate')}
										autoComplete='on'
									/>
									<p style={errorStyles}>
										{errors.startDate && (
											<HStack spacing='5px'>
												<VscWarning /> <Box>{errors.startDate?.message}</Box>
											</HStack>
										)}
									</p>
								</FormControl>
							</Box>
							
							<Box mb="8">
							<hr />
							</Box>

							<Box textAlign='left' mb='8' w='100%'>
								<FormControl>
									<FormLabel>
										<Text>
											Are you legally eligible to work in the United States?<RequiredSpan />
										</Text>
										<Text>
											(Federal Law requires proof of identity and employment
											authorization for all new employees.)
										</Text>
									</FormLabel>
									<RadioGroup>
										<HStack spacing='18px'>
											<Radio
												value='Yes'
												name='legallyEligible'
												{...register('legallyEligible', {required: true})}
											>
												Yes
											</Radio>
											<Radio
												value='No'
												name='legallyEligible'
												{...register('legallyEligible', {required: true})}
											>
												No
											</Radio>
										</HStack>
									</RadioGroup>
								</FormControl>
								<p style={errorStyles}>
										{errors.legallyEligible && (
											<HStack spacing='5px'>
												<VscWarning /> <Box>{errors.legallyEligible?.message}</Box>
											</HStack>
										)}
									</p>
							</Box>

							<Box mb='8' w='100%'>
								<FormControl>
									<Flex w='100%' flexWrap='wrap'>
										<Box w={{ base: '100%', md: '50%' }}>
											<FormLabel>
												Can you travel if the job requires it?<RequiredSpan />
											</FormLabel>
											<RadioGroup>
												<HStack spacing='18px'>
													<Radio
														value='Yes'
														name='travelAvailability'
														{...register('travelAvailability', {required: true})}
													>
														Yes
													</Radio>
													<Radio
														value='No'
														name='travelAvailability'
														{...register('travelAvailability', {required: true})}
													>
														No
													</Radio>
												</HStack>
											</RadioGroup>
											<p style={errorStyles}>
										{errors.travelAvailability && (
											<HStack spacing='5px'>
												<VscWarning /> <Box>{errors.travelAvailability?.message}</Box>
											</HStack>
										)}
									</p>
										</Box>
										<Box
											w={{ base: '100%', md: '50%' }}
											pl={{ base: 0, md: '4' }}
										>
											<FormLabel>
												Do you have a valid driver's license?<RequiredSpan />
											</FormLabel>
											<RadioGroup>
												<HStack spacing='18px' align='center'>
													<Radio
														value='Yes'
														name='driverLicense'
														{...register('driverLicense', {required: true})}
													>
														Yes
													</Radio>
													<Radio
														value='No'
														name='driverLicense'
														{...register('driverLicense', {required: true})}
													>
														No
													</Radio>
													{watchData.driverLicense === 'Yes' && (
														<Box my='2'>
															<FormControl>
																<HStack align='center'>
																	<FormLabel mb='0'>State:</FormLabel>
																	<Input
																		type='text'
																		{...register('driverLicenseState', {
																			required: true,
																		})}
																		autoComplete='on'
																	/>
																	<p style={errorStyles}>
																		{errors.driverLicenseState && (
																			<HStack spacing='5px'>
																				<VscWarning />{' '}
																				<Box>
																					{errors.driverLicenseState?.message}
																				</Box>
																			</HStack>
																		)}
																	</p>
																</HStack>
															</FormControl>
														</Box>
													)}
												</HStack>
											</RadioGroup>
											<p style={errorStyles}>
										{errors.driverLicense && (
											<HStack spacing='5px'>
												<VscWarning /> <Box>{errors.driverLicense?.message}</Box>
											</HStack>
										)}
									</p>
										</Box>
									</Flex>
								</FormControl>
							</Box>
        </>
    )
}

export default PositionApplyingFor;