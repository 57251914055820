
import React, { useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Flex,
	Heading,
    Button,
    Icon,
	Text,
	Radio,
	Textarea,
	Divider,
	RadioGroup
} from '@chakra-ui/react'
import { VscWarning } from 'react-icons/vsc';
import styled from '@emotion/styled';
import SignaturePad from 'react-signature-canvas';
import { BsXCircle } from 'react-icons/bs';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }
const Certification = () => {
    const defaultValues = {};

    const { register, formState: { errors }, control } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    let signatureApplicantCanvas = useRef({});
	
	const formatSignatureApplicantCanvasIntoPng = () => {
		if (signatureApplicantCanvas.current) {
			const dataURL = signatureApplicantCanvas.current.toDataURL()
			console.log(dataURL);
			return dataURL;
		}
	};

    const clearSignatureApplicantCanvas = () =>
		signatureApplicantCanvas.current.clear();
        //signatureApplicantCanvas.current.clear();
    //console.log(watchData);
    return (
        <>
			<Divider mb='8' opacity='1' h='1px' bgColor='#99CCFF' />
			{/* <canvas id="cnv" style={{height: '200px', width: '200px'}} ref={testCanvas}></canvas>
			<button onClick={getTestCanvas}>get canvas</button> */}
		<Box mb='8' w='100%'>
								<FormControl>
									<Box w='100%' mb='8'>
										<FormLabel>
											Have you ever been charged with a crime (other than a
											minor traffic infraction)?
										</FormLabel>
										<RadioGroup mb='8'>
											<HStack spacing='18px'>
												<Radio
													value='Yes'
													name='crimeCharges'
													{...register('crimeCharges')}
												>
													Yes
												</Radio>
												<Radio
													value='No'
													name='crimeCharges'
													{...register('crimeCharges')}
												>
													No
												</Radio>{' '}
											</HStack>
										</RadioGroup>
										{watchData.crimeCharges === 'Yes' && (
											<Box>
												<Box textAlign='left' mb='8' w='100%'>
													<FormControl>
														<FormLabel>If yes, when &amp; where:</FormLabel>
														<Input
															type='text'
															{...register('crimeChargesWhenWhere')}
															autoComplete='on'
														/>
														<p style={errorStyles}>
															{errors.crimeChargesWhenWhere && (
																<HStack spacing='5px'>
																	<VscWarning />{' '}
																	<Box>
																		{errors.crimeChargesWhenWhere?.message}
																	</Box>
																</HStack>
															)}
														</p>
													</FormControl>
												</Box>
												<Box mb='8' w='100%'>
													<FormLabel>Please Explain:</FormLabel>
													<Textarea
														size='sm'
														{...register('explainCrimeCharges')}
														autoComplete='on'
													/>
													{errors.explainCrimeCharges && (
														<HStack spacing='5px' style={errorStyles}>
															<VscWarning />{' '}
															<Box>{errors.explainCrimeCharges?.message}</Box>
														</HStack>
													)}
												</Box>
											</Box>
										)}
									</Box>
								</FormControl>
							</Box>

							
							

							<Box mb='8' w='100%'>
								<FormControl>
									<Box w='100%' mb='8'>
										<FormLabel>
											Are you related by blood or marriage to any person now
											employed by Employer?
										</FormLabel>
										<RadioGroup mb='8'>
											<HStack spacing='18px'>
												<Radio
													value='Yes'
													name='relatedPerson'
													{...register('relatedPerson')}
												>
													Yes
												</Radio>
												<Radio
													value='No'
													name='relatedPerson'
													{...register('relatedPerson')}
												>
													No
												</Radio>{' '}
											</HStack>
										</RadioGroup>
										{watchData.relatedPerson === 'Yes' && (
											<Box textAlign='left' mb='8' w='100%'>
												<FormControl>
													<FormLabel>
														If yes, give name and relationship to you:
													</FormLabel>
													<Input
														type='text'
														{...register('relationship')}
														autoComplete='on'
													/>
													<p style={errorStyles}>
														{errors.relationship && (
															<HStack spacing='5px'>
																<VscWarning />{' '}
																<Box>{errors.relationship?.message}</Box>
															</HStack>
														)}
													</p>
												</FormControl>
											</Box>
										)}
									</Box>
								</FormControl>
							</Box>
							<SectionHeading>
			Certification
		</SectionHeading>
			<Box mb='8'>
				<Text mb='4'>
					I certify that all answers and statements on this application
					are true and complete to the best of my knowledge. I
					understand that should an investigation disclose untruthful or
					misleading answers, my application may be rejected, my name
					removed from consideration, or my employment may be
					terminated.
				</Text>
				<Text>
					I understand and agree that, if hired, my employment is for no
					definite period and either Employer or I may terminate our
					relationship at any time, and that this employment application
					does not constitute an employment contract.
				</Text>
			</Box>

			<Flex w='100%' flexWrap='wrap'>
				<Box mb='8' w={{ base: '100%', md: '60%' }}>
					<FormControl
						isInvalid={!!errors?.signatureApplicantUrl?.message}
						errorText={errors?.signatureApplicantUrl?.message}
						isRequired
					>
						<FormLabel>Signature of Applicant</FormLabel>
						<Box>
							<Controller
								name='signatureApplicantUrl'
								control={control}
								render={({ field }) => (
									<SignaturePad
										ref={signatureApplicantCanvas}
										onEnd={() =>
											field.onChange(
												formatSignatureApplicantCanvasIntoPng(
													signatureApplicantCanvas.current.toDataURL()
												)
											)
										}
										penColor='black'
										canvasProps={{
											style: {
												border: '1px solid #e2e8f0',
												borderRadius: '0.375rem',
												width: '100%',
												height: '120px',
												backgroundColor: 'white',
											},
										}}
									/>
								)}
							/>
							<p style={errorStyles}>
								{errors.signatureApplicantUrl && (
									<HStack spacing='5px'>
										<VscWarning />{' '}
										<Box>{errors.signatureApplicantUrl?.message}</Box>
									</HStack>
								)}
							</p>
						</Box>
						<Box>
							<input
								type='hidden'
								value={new Date()}
								name='timestamp1'
								{...register('timestamp1', { required: true })}
							/>
						</Box>
					</FormControl>
					<Box my='2' textAlign='right'>
						<Button
							size='sm'
							onClick={clearSignatureApplicantCanvas}
							colorScheme='red'
						>
							<Icon as={BsXCircle} mr='2' />
							Clear Signature
						</Button>
					</Box>
				</Box>
				<Box
					mb='8'
					w={{ base: '100%', md: '40%' }}
					pl={{ base: 0, md: '4' }}
				>
					<FormLabel>Date:<RequiredSpan /></FormLabel>
					<Input
						type='date'
						{...register('dateApplicant')}
						autoComplete='on'
					/>
					<p style={errorStyles}>
						{errors.dateApplicant && (
							<HStack spacing='5px'>
								<VscWarning />{' '}
								<Box>{errors.dateApplicant?.message}</Box>
							</HStack>
						)}
					</p>
				</Box>
			</Flex>

			<Box border="1px" borderColor="gray.200" borderRadius="md" p="2" mb="4" >
				<Text m='4'>
					IT IS THE POLICY of the City of Driggs to provide equal
					opportunity in all terms, conditions and privileges of
					employment for all qualified job applicants and employees
					without regard to race, color, national origin, gender or age
					(unless a bona fide job requirement) or the presence of any
					disability. Reasonable accommodations will be made for
					disabled persons.
				</Text>
			</Box>
						
        </>
    )
}

export default Certification;