
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Flex,
	Heading,
	RadioGroup,
	Radio,
	Text,
	Textarea
} from '@chakra-ui/react'
import { VscWarning } from 'react-icons/vsc';
import styled from '@emotion/styled';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }
const Military = () => {
    const defaultValues = {};

    const { register, formState: { errors } } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    //console.log(watchData);
    return (
        <>
		<SectionHeading>
			Military
		</SectionHeading>
			<Box mb='8' w='100%'>
				<FormControl>
					<Box w='100%'>
						<FormLabel>
							Are you a veteran or family member who qualifies for and
							are claiming preference pursuant to Idaho Code § 65-503 or
							its successor?
						</FormLabel>
						<RadioGroup>
							<HStack spacing='18px'>
								<Radio
									value='Yes'
									name='veteran'
									{...register('veteran')}
								>
									Yes
								</Radio>
								<Radio
									value='No'
									name='veteran'
									{...register('veteran')}
								>
									No
								</Radio>
								{watchData.veteran === 'Yes' && (
									<Box my='2'>
										<FormControl>
											<HStack>
												<Text fontWeight='700' pt="4">
													If Yes, fill out the Veteran's Preference form below
													&amp; attach proper documentation.
												</Text>
											</HStack>
										</FormControl>
									</Box>
								)}
							</HStack>
						</RadioGroup>
					</Box>
				</FormControl>
			</Box>

			<Box mb='8' w='100%'>
				<FormControl>
					<Box w='100%'>
						<FormLabel>
							Have you previously claimed such preference?
						</FormLabel>
						<RadioGroup>
							<Radio
								mr='4'
								value='Yes'
								name='claimedPreference'
								{...register('claimedPreference')}
							>
								Yes
							</Radio>
							<Radio
								value='No'
								name='claimedPreference'
								{...register('claimedPreference')}
							>
								No
							</Radio>
						</RadioGroup>
					</Box>
				</FormControl>
			</Box>
        </>
    )
}

export default Military;