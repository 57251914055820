
import React, { useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Textarea,
	Divider,
	Container,
	Box,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputLeftElement,
	Button,
	VStack,
	CheckboxGroup,
	Checkbox,
	Radio,
	RadioGroup,
	HStack,
	Flex,
	Text,
	Spinner,
	Icon,
	Heading,
	Center,
} from '@chakra-ui/react';
import { BsXCircle } from 'react-icons/bs';
import SignaturePad from 'react-signature-canvas';

import { VscWarning } from 'react-icons/vsc';
import styled from '@emotion/styled';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }
const AuthorizationForRelease = () => {
    const defaultValues = {
		applyingFor: '',
		shifts: '',
		contactEmployer: '',
		startDate: '',
		legallyEligible: '',
		travelAvailability: '',
	};

    const { register, formState: { errors }, control } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();

let authorizationReleaseCanvas = useRef({});
let authorizationReleaseWitnessCanvas = useRef({});

const formatAuthorizationReleaseCanvasIntoPng = () => {
	if (authorizationReleaseCanvas.current) {
		const dataURL = authorizationReleaseCanvas.current.toDataURL();
		return dataURL;
	}
};

const formatAuthorizationReleaseWitnessCanvasIntoPng = () => {
	if (authorizationReleaseWitnessCanvas.current) {
		const dataURL = authorizationReleaseWitnessCanvas.current.toDataURL();
		return dataURL;
	}
};

const clearSignatureAuthorizationReleaseCanvas = () =>
	authorizationReleaseCanvas.current.clear();	

const clearAuthorizationReleaseWitnessCanvas = () =>
	authorizationReleaseWitnessCanvas.current.clear();
    return (
        <>
		<SectionHeading>
			Authorization For Release Of Personal Information
		</SectionHeading>
		<Flex mb='4'>
								<Text>
									I,
									<Input
										w={{ base: '100%', md: '25%' }}
										mx='2'
										type='text'
										{...register('applicantForEmployment')}
										autoComplete='on'
									/><p style={errorStyles}>
									{errors.applicantForEmployment && (
										<HStack spacing='5px'>
											<VscWarning />{' '}
											<Box>{errors.applicantForEmployment?.message}</Box>
										</HStack>
									)}
								</p>
									an applicant for employment with the City of Driggs, do hereby
									authorize a review of and full disclosure of all records or
									information concerning myself to any duly authorize agent of
									the City of Driggs, whether the said records are of a public,
									private, or confidential nature.
									
								</Text>

								
							</Flex>

							<Box>
								<Text mb='4'>
									The intent of this authorization is to give my consent for
									full and complete disclosure of all records and information of
									educational institutions; employment and pre-employment
									records, including background reports, efficiency ratings,
									complaints or grievances filed by or against me, either
									criminal or civil, in which I have, or have had any interest
									or involvement.
								</Text>

								<Text mb='4'>
									I understand that any information obtained during any personal
									history background investigation which is developed directly
									or indirectly, in whole or in part, upon this authorization
									will be considered in determining my suitability for
									employment by the hiring party. I hereby agree that any
									person(s) or entities who may furnish such information
									concerning me shall not be held liable for providing this
									information; and I do hereby release said person(s) and
									entities from any and all liability which may be incurred as a
									result of furnishing such information.
								</Text>

								<Text mb='4'>
									I further authorize that a photocopy of this signed release
									form shall be valid as an original thereof, even though the
									said photocopy does not contain an original writing of my
									signature.
								</Text>
							</Box>

							<Flex w='100%' flexWrap='wrap'>
								<Box w={{ base: '100%', md: '50%' }} pr='6'>
									<Box mb='0'>
										<FormControl
											isInvalid={
												!!errors?.signatureAuthorizationRelease?.message
											}
											errorText={errors?.signatureAuthorizationRelease?.message}
											isRequired
										>
											<FormLabel>Signature:</FormLabel>
											<Box>
												<Controller
													name='signatureAuthorizationRelease'
													control={control}
													render={({ field }) => (
														<SignaturePad
															ref={authorizationReleaseCanvas}
															onEnd={() =>
																field.onChange(
																	formatAuthorizationReleaseCanvasIntoPng(
																		authorizationReleaseCanvas.current.toDataURL()
																	)
																)
															}
															penColor='black'
															canvasProps={{
																style: {
																	border: '1px solid #e2e8f0',
																	borderRadius: '0.375rem',
																	width: '100%',
																	height: '200px',
																	backgroundColor: 'white',
																},
															}}
														/>
													)}
												/>
												<p style={errorStyles}>
													{errors.signatureAuthorizationRelease && (
														<HStack spacing='5px'>
															<VscWarning />{' '}
															<Box>
																{errors.signatureAuthorizationRelease?.message}
															</Box>
														</HStack>
													)}
												</p>
											</Box>
											<Box>
												<input
													type='hidden'
													value={new Date()}
													name='timestampAuthorizationRelease'
													{...register('timestampAuthorizationRelease', {
														required: true,
													})}
												/>
											</Box>
										</FormControl>
										<Box textAlign='right' mt='2'>
											<Button
												size='sm'
												onClick={clearSignatureAuthorizationReleaseCanvas}
												colorScheme='red'
											>
												<Icon as={BsXCircle} mr='2' />
												Clear Signature
											</Button>
										</Box>
									</Box>
									<Box mb='8' w={{ base: '100%', md: '100%' }}>
										<FormLabel>Dated:<RequiredSpan /></FormLabel>
										<Input
											type='date'
											{...register('dated')}
											autoComplete='on'
										/>
										<p style={errorStyles}>
											{errors.dated && (
												<HStack spacing='5px'>
													<VscWarning /> <Box>{errors.dated?.message}</Box>
												</HStack>
											)}
										</p>
									</Box>
								</Box>

								<Box w={{ base: '100%', md: '50%' }}>
									<Box mb='0'>
										<FormControl
											isInvalid={
												!!errors?.signatureAuthorizationReleaseWitness?.message
											}
											errorText={
												errors?.signatureAuthorizationReleaseWitness?.message
											}
											isRequired
										>
											<FormLabel>Witness</FormLabel>
											<Box>
												<Controller
													name='signatureAuthorizationReleaseWitness'
													control={control}
													render={({ field }) => (
														<SignaturePad
															ref={authorizationReleaseWitnessCanvas}
															onEnd={() =>
																field.onChange(
																	formatAuthorizationReleaseWitnessCanvasIntoPng(
																		authorizationReleaseWitnessCanvas.current.toDataURL()
																	)
																)
															}
															penColor='black'
															canvasProps={{
																style: {
																	border: '1px solid #e2e8f0',
																	borderRadius: '0.375rem',
																	width: '100%',
																	height: '200px',
																	backgroundColor: 'white',
																},
															}}
														/>
													)}
												/>
												<p style={errorStyles}>
													{errors.signatureAuthorizationReleaseWitness && (
														<HStack spacing='5px'>
															<VscWarning />{' '}
															<Box>
																{
																	errors.signatureAuthorizationReleaseWitness
																		?.message
																}
															</Box>
														</HStack>
													)}
												</p>
											</Box>
											<Box>
												<input
													type='hidden'
													value={new Date()}
													name='timestampAuthorizationReleaseWitness'
													{...register('timestampAuthorizationReleaseWitness', {
														required: true,
													})}
												/>
											</Box>
										</FormControl>
										<Box textAlign='right' mt='2'>
											<Button
												size='sm'
												onClick={clearAuthorizationReleaseWitnessCanvas}
												colorScheme='red'
											>
												<Icon as={BsXCircle} mr='2' />
												Clear Signature
											</Button>
										</Box>
									</Box>
								</Box>
							</Flex>

							<Box mb='8' w={{ base: '100%', md: '50%' }}>
								<FormLabel>
									Printed Name, including all names I have previously used or
									been known by:<RequiredSpan />
								</FormLabel>
								<Textarea {...register('printedNames')} autoComplete='on' />
								<p style={errorStyles}>
									{errors.printedNames && (
										<HStack spacing='5px'>
											<VscWarning /> <Box>{errors.printedNames?.message}</Box>
										</HStack>
									)}
								</p>
							</Box>

							<Box mb='8' w={{ base: '100%', md: '50%' }}>
								<FormLabel>Phone:<RequiredSpan /></FormLabel>
								<Input
									type='tel'
									{...register('phonePrinting')}
									autoComplete='on'
								/>
								<p style={errorStyles}>
									{errors.phonePrinting && (
										<HStack spacing='5px'>
											<VscWarning /> <Box>{errors.phonePrinting?.message}</Box>
										</HStack>
									)}
								</p>
							</Box>

							<Box mb='8' w={{ base: '100%', md: '50%' }}>
								<FormLabel>DOB:<RequiredSpan /></FormLabel>
								<Input type='date' {...register('dobDate')} autoComplete='on' />
								<p style={errorStyles}>
									{errors.dobDate && (
										<HStack spacing='5px'>
											<VscWarning /> <Box>{errors.dobDate?.message}</Box>
										</HStack>
									)}
								</p>
							</Box>
        </>
    )
}

export default AuthorizationForRelease;