
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Text,
	Button,
    Flex,
	Heading
} from '@chakra-ui/react'
import styled from '@emotion/styled';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { VscWarning } from 'react-icons/vsc';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }



const PersonalReference = () => {
	const [indexes, setIndexes] = useState([]);
	const [counter, setCounter] = useState(0);

	const addReference = () => {
		setIndexes(prevIndexes => [...prevIndexes, counter]);
		setCounter(prevCounter => prevCounter + 1);
	}

	const removeReference = (index) => {
		setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
		setCounter(prevCounter => prevCounter - 1);
	}
	
    const defaultValues = {};

    const { register, formState: { errors } } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    //console.log(watchData);
	
    return (
        <>
		<SectionHeading>Personal References</SectionHeading>
		{indexes.map(index => {
			const fieldName = `reference${index}`;
			return (
			<Box border="1px" borderColor="gray.200" borderRadius="md" p="2" mb="4">
			<fieldset name={fieldName} key={fieldName}>
			<Text>Please list the names of three (3) persons not related to you by blood or marriage.</Text>

					<Box mb='8' textAlign='left' w='100%'>
						<FormControl>
							<FormLabel fontWeight='700'>Name:</FormLabel>
							<Flex w='100%' flexWrap='wrap'>
								<Box w={{ base: '100%', md: '33.3%' }}>
									<FormLabel>Last</FormLabel>
									<Input
										type='text'
										{...register(`${fieldName}.perRefLastName`)}
										autoComplete='on'
									/>
								</Box>
								<Box
									w={{ base: '100%', md: '33.3%' }}
									pl={{ base: 0, md: '4' }}
								>
									<FormLabel>First</FormLabel>
									<Input
										type='text'
										{...register(`${fieldName}.perRefFirsttName`)}
										autoComplete='on'
									/>
								</Box>
								<Box
									w={{ base: '100%', md: '33.3%' }}
									pl={{ base: 0, md: '4' }}
								>
									<FormLabel>Middle</FormLabel>
									<Input
										type='text'
										{...register(`${fieldName}.perRefMiddleName`)}
										autoComplete='on'
									/>
								</Box>
							</Flex>
						</FormControl>
					</Box>

					<Box mb='8' textAlign='left' w='100%'>
						<FormControl>
							<FormLabel fontWeight='700'>Address:</FormLabel>
							<Flex w='100%' flexWrap='wrap'>
								<Box w={{ base: '100%', md: '25%' }}>
									<FormLabel>Street</FormLabel>
									<Input
										type='text'
										{...register(`${fieldName}.perRefStreet`)}
										autoComplete='on'
									/>
								</Box>
								<Box
									w={{ base: '100%', md: '25%' }}
									pl={{ base: 0, md: '4' }}
								>
									<FormLabel>City</FormLabel>
									<Input
										type='text'
										{...register(`${fieldName}.perRefCity`)}
										autoComplete='on'
									/>
								</Box>
								<Box
									w={{ base: '100%', md: '25%' }}
									pl={{ base: 0, md: '4' }}
								>
									<FormLabel>State</FormLabel>
									<Input
										type='text'
										{...register(`${fieldName}.perRefState`)}
										autoComplete='on'
									/>
								</Box>
								<Box
									w={{ base: '100%', md: '25%' }}
									pl={{ base: 0, md: '4' }}
								>
									<FormLabel>Zip</FormLabel>
									<Input
										type='number'
										{...register(`${fieldName}.perRefZip`)}
										autoComplete='on'
									/>
								</Box>
							</Flex>
						</FormControl>
					</Box>

					<Box mb='8' textAlign='left' w='100%'>
						<FormControl>
							<FormLabel fontWeight='700'>Telephone:</FormLabel>
							<Flex w='100%' flexWrap='wrap'>
								<Box w={{ base: '100%', md: '50%' }}>
									<FormLabel>Home</FormLabel>
									<Input
										type='tel'
										{...register(`${fieldName}.perRefHomeNumber`)}
										autoComplete='on'
									/>
								</Box>
								<Box
									w={{ base: '100%', md: '50%' }}
									pl={{ base: 0, md: '4' }}
								>
									<FormLabel>Other</FormLabel>
									<Input
										type='tel'
										{...register(`${fieldName}.perRefOtherNumber`)}
										autoComplete='on'
									/>
								</Box>
							</Flex>
						</FormControl>
					</Box>

					<Flex w='100%' flexWrap='wrap'>
						<Box mb='8' w={{ base: '100%', md: '50%' }}>
							<FormLabel>
								Connection To You (i.e. friend, co-worker):
							</FormLabel>
							<Input
								type='text'
								{...register(`${fieldName}.perRefConnection`)}
								autoComplete='on'
							/>
						</Box>
						<Box
							mb='8'
							w={{ base: '100%', md: '50%' }}
							pl={{ base: 0, md: '4' }}
						>
							<FormLabel>Occupation:</FormLabel>
							<Input
								type='text'
								{...register(`${fieldName}.perRefOccupation`)}
								autoComplete='on'
							/>
						</Box>
					</Flex>
				<Box my="4" textAlign="right">
					<Button onClick={() => removeReference(index)} leftIcon={<MinusIcon />} colorScheme="red">Remove reference</Button>
				</Box>
			</fieldset>
			</Box>
			)
		})}
		{counter <= 2 && <Box my="4">
			<Button onClick={() => addReference()} leftIcon={<AddIcon />} colorScheme="green">Add reference</Button>
		</Box> }

        </>
    )
}

export default PersonalReference;