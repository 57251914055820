
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Flex,
	Heading,
} from '@chakra-ui/react'
import { VscWarning } from 'react-icons/vsc';
import styled from '@emotion/styled';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }
const ResumeUpload = () => {
    const defaultValues = {};

    const { register, formState: { errors } } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    //console.log(watchData);
    return (
        <>
		<SectionHeading>
				Upload Your Resume
			</SectionHeading>

			<Box mb='8' textAlign='left' w='100%'>
			<FormControl>
                  <FormLabel>
                    Upload your resume and cover letter as a single file:<br /><small>(Max file size 2 MB)</small> 
                  </FormLabel>
                  <input
                    type="file"
                    id="resume"
                    name="resume"
                    {...register("resume")}
                  />
                </FormControl>
                <p style={errorStyles}>
										{errors.resume && (
											<HStack spacing='5px'>
												<VscWarning />{' '}
												<Box>{errors.resume?.message}</Box>
											</HStack>
										)}
									</p>
			</Box>
        </>
    )
}

export default ResumeUpload;