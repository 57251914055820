
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Flex,
	Heading,
	RadioGroup,
	Radio,
	Text,
	Textarea
} from '@chakra-ui/react'
import { VscWarning } from 'react-icons/vsc';
import styled from '@emotion/styled';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }
const TechnologySkills = () => {
    const defaultValues = {};

    const { register, formState: { errors } } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    //console.log(watchData);
    return (
        <>
		<SectionHeading>
				Technology Skills
			</SectionHeading>
			<Text>List All Skills &amp; Software Applications You Have Experience
			Using
		</Text>

		<Box textAlign='left' mb='8' w='100%'>
			<FormControl>
				<FormLabel>Word Processing:</FormLabel>
				<Input
					type='text'
					{...register('wordProcessing')}
					autoComplete='on'
				/>
				<p style={errorStyles}>
					{errors.wordProcessing && (
						<HStack spacing='5px'>
							<VscWarning />{' '}
							<Box>{errors.wordProcessing?.message}</Box>
						</HStack>
					)}
				</p>
			</FormControl>
		</Box>

		<Box textAlign='left' mb='8' w='100%'>
			<FormControl>
				<FormLabel>Spreadsheet:</FormLabel>
				<Input
					type='text'
					{...register('spreadsheet')}
					autoComplete='on'
				/>
				<p style={errorStyles}>
					{errors.spreadsheet && (
						<HStack spacing='5px'>
							<VscWarning /> <Box>{errors.spreadsheet?.message}</Box>
						</HStack>
					)}
				</p>
			</FormControl>
		</Box>

		<Box textAlign='left' mb='8' w='100%'>
			<FormControl>
				<FormLabel>Other Software:</FormLabel>
				<Input
					type='text'
					{...register('otherSoftware')}
					autoComplete='on'
				/>
				<p style={errorStyles}>
					{errors.otherSoftware && (
						<HStack spacing='5px'>
							<VscWarning />{' '}
							<Box>{errors.otherSoftware?.message}</Box>
						</HStack>
					)}
				</p>
			</FormControl>
		</Box>

		<Box textAlign='left' mb='8' w='100%'>
			<FormControl>
				<FormLabel>Database:</FormLabel>
				<Input
					type='text'
					{...register('database')}
					autoComplete='on'
				/>
				<p style={errorStyles}>
					{errors.database && (
						<HStack spacing='5px'>
							<VscWarning /> <Box>{errors.database?.message}</Box>
						</HStack>
					)}
				</p>
			</FormControl>
		</Box>

		<Box mb='8' w='100%'>
			<FormControl>
				<Flex w='100%' flexWrap='wrap'>
					<Box w={{ base: '100%', md: '50%' }}>
						<HStack spacing='18px'>
							<FormLabel>Microsoft Office?</FormLabel>
							<RadioGroup>
								<Radio
									mr='4'
									value='Yes'
									name='microsoftOffice'
									{...register('microsoftOffice')}
								>
									Yes
								</Radio>
								<Radio
									value='No'
									name='microsoftOffice'
									{...register('microsoftOffice')}
								>
									No
								</Radio>
							</RadioGroup>
						</HStack>
					</Box>
					<Box
						w={{ base: '100%', md: '50%' }}
						pl={{ base: 0, md: '4' }}
					>
						<HStack spacing='18px' align='center'>
							<FormLabel>PowerPoint?</FormLabel>
							<RadioGroup>
								<Radio
									mr='4'
									value='Yes'
									name='powerPoint'
									{...register('powerPoint')}
								>
									Yes
								</Radio>
								<Radio
									value='No'
									name='powerPoint'
									{...register('powerPoint')}
								>
									No
								</Radio>
							</RadioGroup>
						</HStack>
					</Box>
				</Flex>
			</FormControl>
		</Box>

		<Box mb='8' w='100%'>
			<FormControl>
				<Flex w='100%' flexWrap='wrap'>
					<Box w={{ base: '100%', md: '50%' }}>
						<HStack spacing='18px'>
							<FormLabel>Scanner?</FormLabel>
							<RadioGroup>
								<Radio
									mr='4'
									value='Yes'
									name='scanner'
									{...register('scanner')}
								>
									Yes
								</Radio>
								<Radio
									value='No'
									name='scanner'
									{...register('scanner')}
								>
									No
								</Radio>
							</RadioGroup>
						</HStack>
					</Box>
					<Box
						w={{ base: '100%', md: '50%' }}
						pl={{ base: 0, md: '4' }}
					>
						<HStack spacing='18px' align='center'>
							<FormLabel>Copier?</FormLabel>
							<RadioGroup>
								<Radio
									mr='4'
									value='Yes'
									name='copier'
									{...register('copier')}
								>
									Yes
								</Radio>
								<Radio
									value='No'
									name='copier'
									{...register('copier')}
								>
									No
								</Radio>
							</RadioGroup>
						</HStack>
					</Box>
				</Flex>
			</FormControl>
		</Box>

		<Box mb='8' w='100%'>
			<FormControl>
				<Box w='100%'>
					<HStack spacing='18px'>
						<FormLabel>Digital Phone Systems?</FormLabel>
						<RadioGroup>
							<Radio
								mr='4'
								value='Yes'
								name='digitalPhoneSystems'
								{...register('digitalPhoneSystems')}
							>
								Yes
							</Radio>
							<Radio
								value='No'
								name='digitalPhoneSystems'
								{...register('digitalPhoneSystems')}
							>
								No
							</Radio>
						</RadioGroup>
					</HStack>
				</Box>
			</FormControl>
		</Box>

		<Box mb='8'>
			<FormLabel>
				Explain Internet Skills, Including Email Usage:
			</FormLabel>
			<Textarea
				size='sm'
				{...register('explainSkills')}
				autoComplete='on'
			/>
			{errors.explainSkills && (
				<HStack spacing='5px' style={errorStyles}>
					<VscWarning /> <Box>{errors.explainSkills?.message}</Box>
				</HStack>
			)}
		</Box>

		<Box textAlign='left' mb='8' w='100%'>
			<FormControl>
				<FormLabel>
					Professional Licenses or Certificates Held:
				</FormLabel>
				<Input
					type='text'
					{...register('licensesCertificatesHeld')}
					autoComplete='on'
				/>
				<p style={errorStyles}>
					{errors.licensesCertificatesHeld && (
						<HStack spacing='5px'>
							<VscWarning />{' '}
							<Box>{errors.licensesCertificatesHeld?.message}</Box>
						</HStack>
					)}
				</p>
			</FormControl>
		</Box>
        </>
    )
}

export default TechnologySkills;