
import React, { useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Flex,
	Heading,
    Button,
    Icon,
	Text,
	Divider,
	RadioGroup,
	VStack,
	Radio,
	CheckboxGroup,
	Checkbox
} from '@chakra-ui/react'
import { VscWarning } from 'react-icons/vsc';
import styled from '@emotion/styled';
import SignaturePad from 'react-signature-canvas';
import { BsXCircle, BsCheck2Circle } from 'react-icons/bs';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }
const VeteransPreference = () => {
    const defaultValues = {};

    const { register, formState: { errors }, control } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();

	let veteranCanvas = useRef({});

	const formatVeteranCanvasIntoPng = () => {
		if (veteranCanvas.current) {
			const dataURL = veteranCanvas.current.toDataURL();
			return dataURL;
		}
	};

	const clearSignatureVeteranCanvas = () => veteranCanvas.current.clear();

		return (
        <>
			<SectionHeading>
			Veteran's Preference
		</SectionHeading>

							<Box mb='4' w='100%'>
								<Text mb='4' >
									I am claiming Veteran's Preference
								</Text>
								<FormControl>
								
								
								<HStack spacing='18px'>
									<Checkbox
										colorScheme='green'
										value="true"
										name='veteranPreference'
										{...register('veteranPreference')}
									>
										Yes
									</Checkbox>
									</HStack>
								
								
								</FormControl>
								{watchData.veteranPreference === "true" && (
									<Box>
									<Box my='2'>
										<Text>
									Per Idaho Code, Title 65, Chapter 5, Employer will afford a
									preference to employment of veterans. In the event of equal
									qualifications and experience between candidates for an
									available position, a veteran who qualifies will be preferred.
									If claiming veteran's preference, please complete the
									information below and attach a copy of your DD-214 to this
									application.
								</Text>
								<Divider
									w='70%'
									borderBottom={'1px'}
									borderBottomStyle='dashed'
									pb='2'
									m='auto'
								></Divider>
							</Box>

							<Text mb='2' textAlign='center'>
								(Reference Idaho Code, Title 65, Chapter 5, and 5 U.S.C. § 2108)
							</Text>

							<Text mb='8' textAlign='center'>
								The term{' '}
								<Text fontWeight='700' display='inline-block'>
									“active duty”
								</Text>{' '}
								means full-time duty in the Armed Forces, but NOT active duty
								for training.
							</Text>

							<Heading mb='8' as='h5' size='md' w='100%' fontFamily={'proxima-nova, sans-serif'}>
								Part 1. Preference Eligible Veterans:
							</Heading>

							<Box mb='8' w='100%'>
								<FormControl>
									<RadioGroup>
										<VStack wrap align='left'>
											<Radio
												value='I have a service-connected disability of 10% or more.'
												name='eligibleVeterans'
												{...register('eligibleVeterans')}
											>
												I have a service-connected disability of 10% or more.
											</Radio>
											<Radio
												value='I am the spouse of an eligible disabled veteran, who has a service-connected disability.'
												name='eligibleVeterans'
												{...register('eligibleVeterans')}
											>
												I am the spouse of an eligible disabled veteran, who has
												a service-connected disability.
											</Radio>

											<Radio
												value='I am the widow of an eligible disabled veteran and have remained unmarried.'
												name='eligibleVeterans'
												{...register('eligibleVeterans')}
											>
												I am the widow of an eligible disabled veteran and have
												remained unmarried.
											</Radio>
											<Radio
												value='I do not meet any of the selections above, but I served on active duty in the armed forces of the United States for a period of more than one-hundred eighty (180) days and was honorably discharged.'
												name='eligibleVeterans'
												{...register('eligibleVeterans')}
											>
												I do not meet any of the selections above, but I served
												on active duty in the armed forces of the United States
												for a period of more than one-hundred eighty (180) days
												and was honorably discharged.
											</Radio>
										</VStack>
									</RadioGroup>
								</FormControl>
							</Box>

							<Heading mb='8' as='h5' size='md' w='100%' fontFamily={'proxima-nova, sans-serif'}>
								Part 2. Documentation &amp; Signature:
							</Heading>

							<Text mb='4'>
								By my signature, I certify that all statements on this form are
								true and complete to the best of my knowledge. I understand that
								should an investigation disclose inaccurate or misleading
								answers, my application may be rejected and my name removed from
								consideration for employment with Employer.
							</Text>

							<Box textAlign='left' mb='8'>
								<FormControl>
									<CheckboxGroup defaultValue=''>
										<VStack spacing='18px' wrap='true' align='left'>
											<Checkbox
												size='md'
												colorScheme='green'
												value="I have attached a copy of my DD-214. Veteran's preference will not be considered without this document."
												name='veteranDocumentation'
												{...register('veteranDocumentation')}
											>
												I have attached a copy of my DD-214. Veteran's
												preference will not be considered without this document.
											</Checkbox>
										</VStack>
									</CheckboxGroup>
								</FormControl>
								<FormControl>
                  <FormLabel>
                    Upload your DD-214:<br /><small>(Max file size 2 MB)</small>
                  </FormLabel>
                  <input
                    type="file"
                    id="dd214"
                    name="dd214"
                    {...register("dd214")}
                  />
                </FormControl>
				<p style={errorStyles}>
										{errors.dd214 && (
											<HStack spacing='5px'>
												<VscWarning />{' '}
												<Box>{errors.dd214?.message}</Box>
											</HStack>
										)}
									</p>
							</Box>

							<Flex w='100%' flexWrap='wrap'>
								<Box mb='8' w={{ base: '100%', md: '50%' }}>
									<FormLabel>Name (Please Print)</FormLabel>
									<Input
										type='text'
										{...register('veteranName')}
										autoComplete='on'
									/>
									<p style={errorStyles}>
										{errors.veteranName && (
											<HStack spacing='5px'>
												<VscWarning /> <Box>{errors.veteranName?.message}</Box>
											</HStack>
										)}
									</p>
								</Box>
								<Box
									mb='8'
									w={{ base: '100%', md: '50%' }}
									pl={{ base: 0, md: '4' }}
								>
									<FormControl
										isInvalid={!!errors?.veteranImageUrl?.message}
										errorText={errors?.veteranImageUrl?.message}
									>
										<FormLabel>Signature</FormLabel>
										<Box>
											<Controller
												name='veteranImageUrl'
												control={control}
												render={({ field }) => (
													<SignaturePad
														ref={veteranCanvas}
														onEnd={() =>
															field.onChange(
																formatVeteranCanvasIntoPng(
																	veteranCanvas.current.toDataURL()
																)
															)
														}
														penColor='black'
														canvasProps={{
															style: {
																border: '1px solid #e2e8f0',
																borderRadius: '0.375rem',
																width: '100%',
																height: '120px',
																backgroundColor: 'white',
															},
														}}
													/>
												)}
											/>
											<p style={errorStyles}>
												{errors.veteranImageUrl && (
													<HStack spacing='5px'>
														<VscWarning />{' '}
														<Box>{errors.veteranImageUrl?.message}</Box>
													</HStack>
												)}
											</p>
										</Box>
										<Box>
											<input
												type='hidden'
												value={new Date()}
												name='timestamp2'
												{...register('timestamp2', { required: true })}
											/>
										</Box>
									</FormControl>
									<Box my='2' textAlign='right'>
										<Button
											size='sm'
											onClick={clearSignatureVeteranCanvas}
											colorScheme='red'
										>
											<Icon as={BsXCircle} mr='2' />
											Clear Signature
										</Button>
									</Box>
								</Box>
							</Flex>

							<Box textAlign='left' mb='8' w='50%'>
								<FormControl>
									<FormLabel>DATE<RequiredSpan /></FormLabel>
									<Input
										type='date'
										{...register('veteranSignatureDate')}
										autoComplete='on'
									/>
									<p style={errorStyles}>
										{errors.veteranSignatureDate && (
											<HStack spacing='5px'>
												<VscWarning />{' '}
												<Box>{errors.veteranSignatureDate?.message}</Box>
											</HStack>
										)}
									</p>
								</FormControl>
							</Box>	
									</Box>
								)}
							
								</Box>		
        </>
    )
}

export default VeteransPreference;