
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Input,
	Heading,
	Text,
	Table,
	Tbody,
	Thead,
	Tr,
	Th,
	Td,
	VStack
} from '@chakra-ui/react'
import { VscWarning } from 'react-icons/vsc';
import styled from '@emotion/styled';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }
const EducationTraining = () => {
    const defaultValues = {};

    const { register, formState: { errors } } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    //console.log(watchData);
    return (
        <>
		<SectionHeading>
								Education/Training
							</SectionHeading>

							<Table mb='8' size='lg' >
								<Thead>
									<Tr>
										<Th fontFamily="proxima-nova, sans-serif">School</Th>
										<Th fontFamily="proxima-nova, sans-serif">Name</Th>
										<Th fontFamily="proxima-nova, sans-serif">Location</Th>
										<Th fontFamily="proxima-nova, sans-serif">Dates Attended From/To</Th>
										<Th fontFamily="proxima-nova, sans-serif">Diploma, Degree &amp; Major</Th>
										<Th fontFamily="proxima-nova, sans-serif">Graduated?</Th>
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td>High School</Td>
										<Td>
											<Input
												type='text'
												variant='filled'
												{...register('highSchoolName')}
												autoComplete='on'
											/>
										</Td>
										<Td>
											<Input
												type='text'
												variant='filled'
												{...register('highSchoolLocation')}
												autoComplete='on'
											/>
										</Td>
										<Td>
											<VStack>
												<Input
													type='date'
													size='xs'
													variant='filled'
													{...register('highSchoolFromDate')}
													autoComplete='on'
												/>
												<Input
													type='date'
													size='xs'
													variant='filled'
													{...register('highSchoolToDate')}
													autoComplete='on'
												/>
											</VStack>
										</Td>
										<Td>
											<Input
												type='text'
												variant='filled'
												{...register('highSchoolDiploma1')}
												autoComplete='on'
											/>
										</Td>
										<Td>
											<Input
												type='text'
												variant='filled'
												{...register('highSchoolGraduated')}
												autoComplete='on'
											/>
										</Td>
									</Tr>
									<Tr>
										<Td>College</Td>
										<Td>
											<Input
												type='text'
												variant='filled'
												{...register('collegeName')}
												autoComplete='on'
											/>
										</Td>
										<Td>
											<Input
												type='text'
												variant='filled'
												{...register('collegeLocation')}
												autoComplete='on'
											/>
										</Td>
										<Td>
											<VStack>
												<Input
													type='date'
													size='xs'
													variant='filled'
													{...register('collegeFromDate')}
													autoComplete='on'
												/>
												<Input
													type='date'
													size='xs'
													variant='filled'
													{...register('collegeToDate')}
													autoComplete='on'
												/>
											</VStack>
										</Td>
										<Td>
											<Input
												type='text'
												variant='filled'
												{...register('collegeDiploma')}
												autoComplete='on'
											/>
										</Td>
										<Td>
											<Input
												type='text'
												variant='filled'
												{...register('collegeGraduated')}
												autoComplete='on'
											/>
										</Td>
									</Tr>
									<Tr>
										<Td>
											<Text> Other</Text>{' '}
											<Text>(Business, Vocational, Military)</Text>
										</Td>
										<Td>
											<Input
												type='text'
												variant='filled'
												{...register('otherNameBusiness')}
												autoComplete='on'
											/>
										</Td>
										<Td>
											<Input
												type='text'
												variant='filled'
												{...register('otherLocation')}
												autoComplete='on'
											/>
										</Td>
										<Td>
											<VStack>
												<Input
													type='date'
													size='xs'
													variant='filled'
													{...register('otherFromDate')}
													autoComplete='on'
												/>
												<Input
													type='date'
													size='xs'
													variant='filled'
													{...register('otherToDate')}
													autoComplete='on'
												/>
											</VStack>
										</Td>
										<Td>
											<Input
												type='text'
												variant='filled'
												{...register('otherDiploma')}
												autoComplete='on'
											/>
										</Td>
										<Td>
											<Input
												type='text'
												variant='filled'
												{...register('otherGraduated')}
												autoComplete='on'
											/>
										</Td>
									</Tr>
								</Tbody>
							</Table>
        </>
    )
}

export default EducationTraining;