import React, { useState, useRef } from 'react';
import { 
  Box,
  Button,
  Spinner,
  AlertIcon,
  AlertDescription,
  Alert,
  AlertTitle,
} from '@chakra-ui/react';
import { useForm, Controller, useFormContext, FormProvider } from 'react-hook-form';
import SignaturePad from 'react-signature-canvas';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import PersonalInformation from './Sections/PersonalInformation';
import Employer from "./Sections/Employer";
import ResumeUpload from './Sections/ResumeUpload';
import PositionApplyingFor from './Sections/PositionApplyingFor';
import EducationTraining from './Sections/EducationTraining';
import TechnologySkills from './Sections/TechnologySkills';
import Military from './Sections/Military';
import PersonalReference from './Sections/PersonalReference';
import Certification from './Sections/Certification';
import VeteransPreference from './Sections/VeteransPreference';
import AuthorizationForRelease from './Sections/AuthorizationForRelease';

const schema = yup.object().shape({
	applicantLastName: yup.string().required('Last Name is required'),
	applicantFirstName: yup.string().required('First Name is required'),
	applicantStreet: yup.string().required('Street is required'),
	applicantCity: yup.string().required('City is required'),
	applicantState: yup.string().required('State is required'),
	applicantZip: yup.string().required('Zip code is required'),
	applicantCellNumber: yup
		.string()
		.required('A phone number is required')
		.matches(
			/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
			'Invalid phone number'
		),
	applicantEmailAddress: yup
	.string()
	.required('Email address is required')
	.matches(
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		'Invalid email'
	),

	applyingFor: yup.string().required("Please choose a type").nullable(),
	shifts: yup.string().required("Please choose a shift").nullable(),
	contactEmployer: yup.string().required("Please choose a contact").nullable(),

	legallyEligible: yup.string().required("Please choose").nullable(),
	travelAvailability: yup.string().required("Please choose").nullable(),
	driverLicense: yup.string().required("Please choose").nullable(),
	applicantJobTitle: yup.string().required('Job position is required'),
	startDate: yup.date().typeError('Start date is required'),
	phonePrinting: yup.string().required("Phone is required"),

	signatureApplicantUrl: yup
	.string()
	.required('Signature is required.')
	.matches(
		/^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
		'Signature must be png'
	),
	// veteranImageUrl: yup
	// 	.string()
	// 	.required('Signature is required.')
	// 	.matches(
	// 		/^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
	// 		'Signature must be png'
	// 	),
	signatureAuthorizationReleaseWitness: yup
		.string()
		.required('Signature is required.')
		.matches(
			/^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
			'Signature must be png'
		),
	signatureAuthorizationRelease: yup
		.string()
		.required('Signature is required.')
		.matches(
			/^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
			'Signature must be png'
	),
	dateApplicant: yup.date().typeError('Date is required'),
	// veteranSignatureDate: yup.date().typeError('Date is required'),
	timestampAuthorizationReleaseWitness: yup.date().typeError('Date is required'),
	dated: yup.date().typeError('Date is required'),
	dobDate: yup.date().typeError('Date is required'),
	applicantForEmployment: yup.string().required('Name is required'),
	printedNames: yup.string().required('Name(s) is/are required'),
	resume: yup.mixed()
    .test('fileSize', 'Max file size 2 MB', (value, context) => {
    if (!value || !value.length) {
        return true;
    } else {
    return value && value[0] && value[0].size <= 2000000;
    }
  }),
	dd214: yup.mixed()
    .test('fileSize', 'Max file size 2 MB', (value, context) => {
    if (!value || !value.length) {
        return true;
    } else {
    return value && value[0] && value[0].size <= 2000000;
    }
  })

	// perRefHomeNumber1: yup
	// 	.string()
	// 	.matches(
	// 		/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
	// 		'Invalid phone number'
	// 	),
	// perRefHomeNumber2: yup
	// 	.string()
	// 	.matches(
	// 		/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
	// 		'Invalid phone number'
	// 	),
	// perRefHomeNumber3: yup
	// 	.string()
	// 	.matches(
	// 		/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
	// 		'Invalid phone number'
	// 	),
	// perRefOtherNumber1: yup
	// 	.string()
	// 	.matches(
	// 		/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
	// 		'Invalid phone number'
	// 	),
	// perRefOtherNumber2: yup
	// 	.string()
	// 	.matches(
	// 		/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
	// 		'Invalid phone number'
	// 	),
	// perRefOtherNumber3: yup
	// 	.string()
	// 	.matches(
	// 		/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
	// 		'Invalid phone number'
	// 	),

	
	
	// highSchoolFromDate: yup.date().typeError('Must be a date'),
	// highSchoolToDate: yup.date().typeError('Must be a date'),
	// collegeFromDate: yup.date().typeError('Must be a date'),
	// collegeToDate: yup.date().typeError('Must be a date'),
	// otherFromDate: yup.date().typeError('Must be a date'),
	// otherToDate: yup.date().typeError('Must be a date'),
	// dateFromEmploymentHistory: yup.date().typeError('Must be a date'),
	// toDateEmploymentHistory: yup.date().typeError('Must be a date'),
	// dateFromNextEmployer1: yup.date().typeError('Must be a date'),
	// toDateFromNextEmployer1: yup.date().typeError('Must be a date'),
	// dateFromNextEmployer2: yup.date().typeError('Must be a date'),
	// toDateNextEmployer2: yup.date().typeError('Must be a date'),
	

});

const scrollTop = () => {
    window.scrollTo(0, 0);
  };

export default function EmploymentApplication() {

  const [succeeded, setSucceeded] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    });
     

  const watchData = methods.watch();
  //console.log(watchData);

  // create a Ref to access our form element
	const formRef = useRef(null);

  const FormSuccess = () => (
    <Box my="20">
    <Alert status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
      >
    <AlertIcon boxSize='40px' mr={0}/>
      <AlertTitle mb="4">
        Application submitted successfully!
        </AlertTitle>
        <AlertDescription maxWidth='md'>
    Thanks for your submission. We will be in touch soon.
  </AlertDescription>
    </Alert>
  </Box>
  )


  const sendFormData = async (data) => {
		console.log(data);
		//event.preventDefault()
		setIsSubmitting(true);

		if (!formRef.current) {
			console.log('something went wrong with the ref');
		}

		const formData = new FormData(formRef.current);
		
		// convert signature to blob
		const applicantSignatureBlob = await (await fetch(watchData.signatureApplicantUrl)).blob([applicantSignatureBlob], "applicantSignature.png");
		const veteranSignatureBlob = await (await fetch(watchData.veteranImageUrl)).blob([veteranSignatureBlob], "veteranSignature.png");
		const signatureAuthorizationReleaseBlob = await (await fetch(watchData.signatureAuthorizationRelease)).blob([signatureAuthorizationReleaseBlob], "signatureAuthorizationReleaseWitness.png");
		const signatureAuthorizationReleaseWitnessBlob = await (await fetch(watchData.signatureAuthorizationReleaseWitness)).blob([signatureAuthorizationReleaseWitnessBlob], "signatureAuthorizationRelease.png");

		// append blob to formData
		formData.append("signatureApplicantUrl", applicantSignatureBlob, "applicantSignature.png");
		formData.append("veteranImageUrl", veteranSignatureBlob, "veteranSignature.png");
		formData.append("signatureAuthorizationRelease", signatureAuthorizationReleaseBlob, "signatureAuthorizationRelease.png");
		formData.append("signatureAuthorizationReleaseWitness", signatureAuthorizationReleaseWitnessBlob, "signatureAuthorizationReleaseWitness.png");
		
		//formData.append('signatureApplicantUrl', watchData.signatureApplicantUrl);
		//formData.append('veteranImageUrl', watchData.veteranImageUrl);
		//formData.append('signatureAuthorizationRelease', watchData.signatureAuthorizationRelease);
		//formData.append('signatureAuthorizationReleaseWitness', watchData.signatureAuthorizationReleaseWitness);


		// Use console.log() to see the data that is being sent to the server
		console.log(Array.from(formData.entries()));

	   fetch('/.netlify/functions/send-job-application', {
		       method: 'POST',
		       body: formData,
			   // don't send headers due to boundary problem 
		   })
		   // Convering the response to JSON breaks status code
		   .then(res => {
		       console.log(res)
		          if (res.status === 200) {
		                setSucceeded(true)
		                setIsSubmitting(false)
		                methods.reset()
						scrollTop()
		            } else {
		                setSucceeded(false)
		                console.log("network error")
						alert("Network error. Please try again later.")
		            }
		   }
		)
	};

  return (
    <Box id='theForm'>
				{succeeded ? (
					<FormSuccess />
				) : (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(sendFormData)} ref={formRef} >
        
		<PersonalInformation />

		<PositionApplyingFor />

		<EducationTraining />
    
        <Employer />

		<TechnologySkills />

		<Military />

		<PersonalReference />

		<Certification />

		<VeteransPreference />

        <ResumeUpload />

		<AuthorizationForRelease />

        <Box mb='12'>
          <Button type='submit' textAlign='left' disabled={isSubmitting}>
            {isSubmitting ? (
              <Spinner />
            ) : succeeded ? (
              'Submitted!'
            ) : (
              'Submit'
            )}
          </Button>
        </Box>
      </form>
    </FormProvider>
    )}
  </Box>
  );
}