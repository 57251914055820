
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    HStack,
	Button,
    Flex,
    InputGroup,
    InputLeftElement,
    Textarea,
	Heading,
	Text
} from '@chakra-ui/react'
import styled from '@emotion/styled';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { VscWarning } from 'react-icons/vsc';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }



const Employer = () => {
	const [indexes, setIndexes] = useState([]);
	const [counter, setCounter] = useState(0);

	const addEmployer = () => {
		setIndexes(prevIndexes => [...prevIndexes, counter]);
		setCounter(prevCounter => prevCounter + 1);
	}

	const removeEmployer = (index) => {
		setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
		setCounter(prevCounter => prevCounter - 1);
	}
	
    const defaultValues = {};

    const { register, formState: { errors } } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    //console.log(watchData);
	
    return (
        <>
		<SectionHeading>Previous Employment</SectionHeading>
		<Text>
			Please Start With the Most Recent, Ending With Age 18,
			Excluding Part-Time Positions Held While Obtaining Higher
			Maximum 10 previous employers.
		</Text>
		{indexes.map(index => {
			const fieldName = `employer${index}`;
			return (
			<Box border="1px" borderColor="gray.200" borderRadius="md" p="2" mb="4">
			<fieldset name={fieldName} key={fieldName}>
				<Box textAlign='left' mb='8' w='100%'>
	            <FormControl>
	                <FormLabel>Employer</FormLabel>
	                <Input
	                    type='text'
	                    {...register(`${fieldName}.employer`)}
	                    autoComplete='on'
	                />
	                <p style={errorStyles}>
	                    {errors.employerName1 && (
	                        <HStack spacing='5px'>
	                            <VscWarning />{' '}
	                            <Box>{errors.employerName1?.message}</Box>
	                        </HStack>
	                    )}
	                </p>
	            </FormControl>
	        	</Box>
				<Box mb='8' textAlign='left' w='100%'>
								<FormControl>
									<FormLabel>Address:</FormLabel>
									<Flex w='100%' flexWrap='wrap'>
										<Box w={{ base: '100%', md: '25%' }}>
											<FormLabel>Street</FormLabel>
											<Input
												type='text'
												{...register(`${fieldName}.street`)}
												autoComplete='on'
											/>
										</Box>
										<Box
											w={{ base: '100%', md: '25%' }}
											pl={{ base: 0, md: '4' }}
										>
											<FormLabel>City</FormLabel>
											<Input
												type='text'
												{...register(`${fieldName}.city`)}
												autoComplete='on'
											/>
										</Box>
										<Box
											w={{ base: '100%', md: '25%' }}
											pl={{ base: 0, md: '4' }}
										>
											<FormLabel>State</FormLabel>
											<Input
												type='text'
												{...register(`${fieldName}.state`)}
												autoComplete='on'
											/>
										</Box>
										<Box
											w={{ base: '100%', md: '25%' }}
											pl={{ base: 0, md: '4' }}
										>
											<FormLabel>Zip</FormLabel>
											<Input
												type='number'
												{...register(`${fieldName}.zip`)}
												autoComplete='on'
											/>
										</Box>
									</Flex>
								</FormControl>
							</Box>

							<Flex w='100%' flexWrap='wrap'>
								<Box mb='8' w={{ base: '100%', md: '50%' }}>
									<FormLabel>Telephone:</FormLabel>
									<Input
										type='tel'
										{...register(`${fieldName}.telephone`)}
										autoComplete='on'
									/>
								</Box>
								<Box
									mb='8'
									w={{ base: '100%', md: '50%' }}
									pl={{ base: 0, md: '4' }}
								>
									<FormLabel>Supervisor Name:</FormLabel>
									<Input
										type='text'
										{...register(`${fieldName}.supervisorName`)}
										autoComplete='on'
									/>
								</Box>
							</Flex>

							<Flex w='100%' flexWrap='wrap'>
								<Box mb='8' w={{ base: '100%', md: '33.3%' }}>
									<FormLabel>Dates From:</FormLabel>
									<Input
										type='date'
										{...register(`${fieldName}.dateFrom`)}
										autoComplete='on'
									/>
								</Box>
								<Box
									mb='8'
									w={{ base: '100%', md: '33.3%' }}
									pl={{ base: 0, md: '4' }}
								>
									<FormLabel>To:</FormLabel>
									<Input
										type='date'
										{...register(`${fieldName}.dateTo`)}
										autoComplete='on'
									/>
								</Box>
								<Box
									mb='8'
									w={{ base: '100%', md: '33.3%' }}
									pl={{ base: 0, md: '4' }}
								>
									<FormLabel>Final Rate of Pay:</FormLabel>
									<InputGroup>
										<InputLeftElement
											pointerEvents='none'
											fontSize='1.2em'
											children='$'
										/>
										<Input
											type='text'
											{...register(`${fieldName}.finalRateOfPay`)}
											autoComplete='on'
										/>
									</InputGroup>
								</Box>
							</Flex>

							<Box textAlign='left' mb='8' w='100%'>
								<FormControl>
									<FormLabel>Position Held:</FormLabel>
									<Input
										type='text'
										{...register(`${fieldName}.positionHeld`)}
										autoComplete='on'
									/>
								</FormControl>
							</Box>

							<Box mb='8'>
								<FormLabel>Primary Duties:</FormLabel>
								<Textarea
									size='sm'
									{...register(`${fieldName}.primaryDuties`)}
									autoComplete='on'
								/>
							</Box>

							<Box textAlign='left' mb='8' w='100%'>
								<FormControl>
									<FormLabel>Reason for Leaving:</FormLabel>
									<Input
										type='text'
										{...register(`${fieldName}.reasonForLeaving`)}
										autoComplete='on'
									/>
								</FormControl>
							</Box>
				<Box my="4" textAlign="right">
					<Button onClick={() => removeEmployer(index)} leftIcon={<MinusIcon />} colorScheme="red">Remove employer</Button>
				</Box>
			</fieldset>
			</Box>
			)
		})}
		{counter <= 9 && (
			<Box my="4"><Button onClick={() => addEmployer()} leftIcon={<AddIcon />} colorScheme="green">Add employer</Button></Box> )
			}
		

        </>
    )
}

export default Employer;