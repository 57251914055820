
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Flex,
	Heading,
} from '@chakra-ui/react'
import { VscWarning } from 'react-icons/vsc';
import styled from '@emotion/styled';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }
const PersonalInformation = () => {
    const defaultValues = {};

    const { register, formState: { errors } } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    //console.log(watchData);
    return (
        <>
		<SectionHeading>
				Personal Information
			</SectionHeading>

			<Box mb='8' textAlign='left' w='100%'>
				<FormControl>
					<FormLabel fontWeight='700'>Name:</FormLabel>
					<Flex w='100%' flexWrap='wrap'>
						<Box w={{ base: '100%', md: '25%' }}>
							<FormLabel>Last<RequiredSpan /></FormLabel>
							<Input
								type='text'
								{...register('applicantLastName')}
								autoComplete='on'
							/>
							<p style={errorStyles}>
								{errors.applicantLastName && (
									<HStack spacing='5px'>
										<VscWarning />{' '}
										<Box>{errors.applicantLastName?.message}</Box>
									</HStack>
								)}
							</p>
						</Box>
						<Box
							w={{ base: '100%', md: '25%' }}
							pl={{ base: 0, md: '4' }}
						>
							<FormLabel>First<RequiredSpan /></FormLabel>
							<Input
								type='text'
								{...register('applicantFirstName')}
								autoComplete='on'
							/>
							<p style={errorStyles}>
								{errors.applicantFirstName && (
									<HStack spacing='5px'>
										<VscWarning />{' '}
										<Box>{errors.applicantFirstName?.message}</Box>
									</HStack>
								)}
							</p>
						</Box>
						<Box
							w={{ base: '100%', md: '25%' }}
							pl={{ base: 0, md: '4' }}
						>
							<FormLabel>Middle</FormLabel>
							<Input
								type='text'
								{...register('applicantMiddleName')}
								autoComplete='on'
							/>
							<p style={errorStyles}>
								{errors.applicantMiddleName && (	
									<HStack spacing='5px'>
										<VscWarning />{' '}
										<Box>{errors.applicantMiddleName?.message}</Box>
									</HStack>
								)}
							</p>
						</Box>
						<Box
							w={{ base: '100%', md: '25%' }}
							pl={{ base: 0, md: '4' }}
						>
							<FormLabel>Other Names Used</FormLabel>
							<Input
								type='text'
								{...register('applicantOtherNames')}
								autoComplete='on'
							/>
							<p style={errorStyles}>
								{errors.aplicantOtherNames && (
									<HStack spacing='5px'>
										<VscWarning />{' '}
										<Box>{errors.applicantOtherNames?.message}</Box>
									</HStack>
								)}
							</p>
						</Box>
					</Flex>
				</FormControl>
			</Box>

			<Box mb='8' textAlign='left' w='100%'>
				<FormControl>
					<FormLabel fontWeight='700'>Address:</FormLabel>
					<Flex w='100%' flexWrap='wrap'>
						<Box w={{ base: '100%', md: '25%' }}>
							<FormLabel>Street<RequiredSpan /></FormLabel>
							<Input
								type='text'
								{...register('applicantStreet')}
								autoComplete='on'
							/>
							<p style={errorStyles}>
								{errors.applicantStreet && (
									<HStack spacing='5px'>
										<VscWarning />{' '}
										<Box>{errors.applicantStreet?.message}</Box>
									</HStack>
								)}
							</p>
						</Box>
						<Box
							w={{ base: '100%', md: '25%' }}
							pl={{ base: 0, md: '4' }}
						>
							<FormLabel>City<RequiredSpan /></FormLabel>
							<Input
								type='text'
								{...register('applicantCity')}
								autoComplete='on'
							/>
							<p style={errorStyles}>
								{errors.applicantCity && (
									<HStack spacing='5px'>
										<VscWarning />{' '}
										<Box>{errors.applicantCity?.message}</Box>
									</HStack>
								)}
							</p>
						</Box>
						<Box
							w={{ base: '100%', md: '25%' }}
							pl={{ base: 0, md: '4' }}
						>
							<FormLabel>State<RequiredSpan /></FormLabel>
							<Input
								type='text'
								{...register('applicantState')}
								autoComplete='on'
							/>
							<p style={errorStyles}>
								{errors.applicantState && (
									<HStack spacing='5px'>
										<VscWarning />{' '}
										<Box>{errors.applicantState?.message}</Box>
									</HStack>
								)}
							</p>
						</Box>
						<Box
							w={{ base: '100%', md: '25%' }}
							pl={{ base: 0, md: '4' }}
						>
							<FormLabel>Zip<RequiredSpan /></FormLabel>
							<Input
								type='number'
								{...register('applicantZip')}
								autoComplete='on'
							/>
							<p style={errorStyles}>
								{errors.applicantZip && (
									<HStack spacing='5px'>
										<VscWarning />{' '}
										<Box>{errors.applicantZip?.message}</Box>
									</HStack>
								)}
							</p>
						</Box>
					</Flex>
				</FormControl>
			</Box>

			<Box mb='8' textAlign='left' w='100%'>
				<FormControl>
					<FormLabel fontWeight='700'>Telephone:</FormLabel>
					<Flex w='100%' flexWrap='wrap'>
						<Box w={{ base: '100%', md: '33.3%' }}>
							<FormLabel>Home</FormLabel>
							<Input
								type='tel'
								{...register('applicantHomeNumber')}
								autoComplete='on'
							/>
							<p style={errorStyles}>
								{errors.applicantHomeNumber && (
									<HStack spacing='5px'>
										<VscWarning />{' '}
										<Box>{errors.applicantHomeNumber?.message}</Box>
									</HStack>
								)}
							</p>
						</Box>
						<Box
							w={{ base: '100%', md: '33.3%' }}
							pl={{ base: 0, md: '4' }}
						>
							<FormLabel>Cell/Other<RequiredSpan /></FormLabel>
							<Input
								type='tel'
								{...register('applicantCellNumber')}
								autoComplete='on'
							/>
							<p style={errorStyles}>
								{errors.applicantCellNumber && (
									<HStack spacing='5px'>
										<VscWarning />{' '}
										<Box>{errors.applicantCellNumber?.message}</Box>
									</HStack>
								)}
							</p>
						</Box>
						<Box
							w={{ base: '100%', md: '33.3%' }}
							pl={{ base: 0, md: '4' }}
						>
							<FormLabel>Message</FormLabel>
							<Input
								type='tel'
								{...register('applicantMessageNumber')}
								autoComplete='on'
							/>
							<p style={errorStyles}>
								{errors.applicantMessageNumber && (
									<HStack spacing='5px'>
										<VscWarning />{' '}
										<Box>{errors.applicantMessageNumber?.message}</Box>
									</HStack>
								)}
							</p>
						</Box>
					</Flex>
				</FormControl>
			</Box>

			<Box textAlign='left' mb='8' w='100%'>
				<FormControl>
					<FormLabel>Email Address<RequiredSpan /></FormLabel>
					<Input
						type='email'
						{...register('applicantEmailAddress')}
						autoComplete='on'
					/>
					<p style={errorStyles}>
						{errors.applicantEmailAddress && (
							<HStack spacing='5px'>
								<VscWarning />{' '}
								<Box>{errors.applicantEmailAddress?.message}</Box>
							</HStack>
						)}
					</p>
				</FormControl>
			</Box>

			<Box textAlign='left' mb='8' w='100%'>
				<FormControl>
					<FormLabel>Webpage Address(es)</FormLabel>
					<Input
						type='text'
						{...register('webpageAddress')}
						autoComplete='on'
					/>
					<p style={errorStyles}>
						{errors.webpageAddress && (
							<HStack spacing='5px'>
								<VscWarning />{' '}
								<Box>{errors.webpageAddress?.message}</Box>
							</HStack>
						)}
					</p>
				</FormControl>
			</Box>
        </>
    )
}

export default PersonalInformation;